// xl - Extra large devices (large desktops, 1200px and up)

.first-screen {
  &__text {
    min-height: 450px;
    font-size: 55px;
    line-height: 38px; } }

.select {
  &__item {
    &.active {
      &:after {
        display: block; } } } }

.tabs {
  &__content {
    &-container {
      min-height: 400px; } } }
