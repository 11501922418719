// sm - Small devices (landscape phones, 576px and up)
// xl - Extra large devices (large desktops, 1200px and up)

.header {
  padding-bottom: 0;
  &__contacts {
    font-size: 10px;
    padding: 10px 15px;
    .contact {
      @include dFlex(flex-start, center);
      padding: 0; } }
  &__social {
    order: 0;
    margin-bottom: 0;
    @include dFlex(flex-end, center);
    .social {
      height: 100%; } } }

.menu {
  height: auto;
  padding-top: 15px;
  &__container {
    background-color: transparent; }
  &__burger {
    display: none; }
  &__links {
    @include dFlex(center, center);
    padding: 0;
    height: auto;
    li {
      @include dFlex(center, center);
      width: auto;
      height: 50px;
      a {
        position: relative;
        &:before {
          width: 10px;
          height: 10px;
          position: absolute;
          top: -5px;
          left: -5px;
          z-index: -1; } }
      &:first-of-type {
        padding-left: 5px; }
      &:not(:last-of-type) {
        a {
          &:after {
            content: '/';
            font-size: 12px;
            padding: 0 8px; } } } } } }

.breadcrumbs {
  padding: 15px 15px;
  font-size: 16px;
  line-height: 21px; }

.first-screen {
  &__text {
    font-size: 55px;
    line-height: 38px; }
  &__carousel {
    margin: 25px 0;
    width: 100%;
    .carousel {
      &-caption {
        h5 {
          font-size: 32px;
          line-height: 40px; }
        p {
          font-size: 18px;
          line-height: 22px; } } } } }

.item {
  .carousel {
    &-caption {
      h5 {
        font-size: 24px;
        line-height: 30px; } } } }

.tabs {
  &__content {
    &-container {
      min-height: 250px;
      .video {
        &__play {
          font-size: 100px; } } } } }

.blog-cards {
  column-count: 2;
  &_post {
    column-count: 1; } }
