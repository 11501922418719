@import 'node_modules/retinajs/dist/retina.sass';
@import 'mixins';
@import 'fonts';
@import 'theme';

body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  min-width: 320px; }
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }
.hidden {
  display: none; }
a {
  &:link,
  &:visited {
    text-decoration: none; }
  &:hover,
  &:active {
    text-decoration: underline; } }
ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    display: inline-block;
    a {
      display: block; } } }

input,
select {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 100;
  border: 1px solid;
  padding: 0 10px;
  padding-right: 36px;
  height: 30px; }

menu {
  margin: 0; }

.img-container {
  @include retina('', 2, cover, center center no-repeat);
  &.img-container_contain {
    @include retina('', 2, contain, center center no-repeat); } }


@import 'adaptive';

