/* Site color theme */

$headerBg: rgba(#000,1);
$headerText: rgba(#fff,1);
$accentColor: rgba(#F4CD00,1);
$inputBg: rgba(#fff,.75);
$secondaryBg: rgba(#BBBDC0,1);
$mainText: rgba(#000,1);

input[type='text'],
select {
  background-color: $inputBg;
  border-color: $secondaryBg;
  color: $secondaryBg; }

.header {
  background-color: $headerBg;
  &__contacts {
    .contact {
      color: $headerText;
      a {
        color: $accentColor; } } }
  &__social {
    .social {
      &__container {
        &:before {
          background-color: $accentColor; }
        a {
          color: $headerBg; } } } } }
.menu {
  background-color: $headerText;
  &__container {
    &.active {
      background-color: $headerText; } }
  &__burger {}
  &__button {
    background-color: $headerText; }
  &__symbol {
    span {
      background-color: $headerBg; } }
  &__links {
    li {
      a {
        color: $secondaryBg;
        &:hover,
        &:active,
        &.active {
          color: $mainText;
          &:before {
            background-color: $accentColor; } }
        &:after {
          color: $secondaryBg; } } } } }

.breadcrumbs {
  background-color: $headerBg;
  color: rgba($headerText,.5);
  a {
    color: $headerText; } }

.first-screen {
  background-color: rgba($headerBg,.25);
  &_catalog {
    background-color: rgba($headerText,.75); }
  &_blog {
    background-color: rgba($headerText,.75);
    &-post {
      background-color: rgba($headerText,.75); } }
  &__text {
    color: $headerText;
    span {
      color: $accentColor; } }
  &__carousel {
    box-shadow: 0px 0px 30px 0px $accentColor;
    background-color: rgba($headerBg,.75);
    .carousel {
      &-indicators {
        li {
          border-color: $accentColor;
          background-color: $headerText;
          box-shadow: 0px 0px 10px 0px $headerText;
          &.active {
            background-color: $accentColor; } } }
      &-caption {
        color: $headerBg;
        background-color: rgba($headerText,.25);
        text-shadow: 0px 0px 10px $headerText;
        h5 {
          span {
            &:after {
              background-color: $accentColor; } } } } } } }

h4 {
  color: $secondaryBg; }

.type-card {
  &__overlay {
    background-color: $headerBg; }
  &__header {
    &:after {
      background-color: $headerText; } }
  &__link {
    a {
      &:before {
        background-color: $accentColor; }
      &:after {
        background-color: $headerText; } } }
  &__container {
    color: $headerText;
    &:hover {
      color: $headerBg;
      .type-card {
        &__overlay {
          background-color: $accentColor; }
        &__header {
          &:after {
            background-color: $headerBg; } }
        &__link {
          a {
            &:before {
              background-color: $secondaryBg; }
            &:after {
              background-color: $headerBg; } } } } } } }
.screen-header,
.screen-description {
  color: $secondaryBg;
  &_black {
    color: $headerBg; } }

.select {
  &__header {
    background-color: $accentColor;
    color: $headerBg; }
  a {
    &.select {
      &__item {
        border-color: $headerBg;
        background-color: $secondaryBg;
        color: $headerText;
        &:hover,
        &:active,
        &.active {
          background-color: $headerBg;
          color: $headerText;
          &:after {
            background-color: $headerBg; } } } } } }
.params {
  &__download {
    color: $headerBg;
    background-color: $accentColor;
    &:hover,
    &:active {
      color: $headerText; } }
  &__item {
    border-color: $secondaryBg; }
  &__name {
    color: $secondaryBg; }
  &__value {
    color: $headerBg;
    &_accent {
      color: $accentColor; } }
  &__header {
    color: $headerBg;
    &_secondary {
      color: $secondaryBg; } } }
.button {
  background-color: $accentColor;
  color: $headerBg;
  &:before {
    background-color: $accentColor;
    box-shadow: 0px 3px 10px 0px rgba($headerBg,.16);
    transition: ease-in .25s; }
  &:hover {
    &:before {
      box-shadow: 0px 3px 20px 0px rgba($headerBg,.25); } } }
.item {
  .carousel {
    &-controls {
      .button {
        color: $headerBg; } }
    &-caption {
      color: $headerBg; } } }

.third-screen {
  background-color: rgba($headerText,.5); }
.tabs {
  &__button {
    background-color: rgba($headerText,.75);
    color: $headerBg;
    &.active,
    &:hover,
    &:active {
      background-color: rgba($headerBg,.75);
      box-shadow: 0px 0px 50px 0px $accentColor;
      color: $headerText; } }
  &__content {
    &_preloader {
      &:after {
        background-color: $headerBg !important; } } }
  &__card {
    &-img {
      .carousel {
        &-indicators {
          background-color: rgba($headerText,.25);
          li {
            border-color: $accentColor;
            background-color: $headerText;
            &:hover,
            &.active {
              background-color: $accentColor; } } } } }
    &-text {
      background-color: rgba($headerBg,.75);
      color: $headerText;
      h5 {
        &:after {
          background-color: $headerText; } }
      a {
       color: inherit;
       background-color: transparent;
       &:before {
        background-color: $accentColor; }
       &:after {
        background-color: $headerText; } } }
    &:hover {
      .tabs {
        &__card {
          &-text {
           background-color: rgba($accentColor,.9);
           color: $headerBg;
           h5 {
            &:after {
              background-color: $headerBg; } }
           a {
            color: inherit;
            &:before {
              background-color: $secondaryBg; }
            &:after {
              background-color: $headerBg; } } } } } } } }

.fourth-screen {
  background-color: rgba($headerBg,.75); }

.parthner {
  &__card {
    &-container {
      background-color: rgba($secondaryBg,.25);
      box-shadow: 0px 0px 50px 0px $accentColor;
      color: $headerText; } }
  &__icon {
    svg {
      color: $accentColor; } }
  &__header {
    &:after {
      background-color: $accentColor; } } }

.contacts {
  &-screen {
    background-color: rgba($headerBg,.27); }
  &-place {
    &__header {
      color: $headerText; }
    &__container {}
    &__text {
      background-color: rgba($headerBg,.75);
      color: $secondaryBg;
      a {
        color: inherit; } } }
  &-form {
    &__container {
      background-color: $headerText;
      color: $headerBg; }
    &__header {
      span {} }
    form {
      textarea,
      input[type='text'],
      input[type='phone'],
      input[type='email'] {
        border: 1px solid $secondaryBg;
        color: $headerBg; } } } }
.video {
  &__play {
    color: rgba($accentColor,.75);
    text-shadow: 0px 0px 5px rgba($headerBg,.16);
    &:hover {
      color: $accentColor;
      text-shadow: 0px 0px 10px rgba($headerText,.5); } } }

.footer {
  &__social {
    a {
      color: rgba($headerBg,.25); } }
  &__header {
    color: rgba($headerBg,.5); }
  &__links {
    a {
      color: $headerBg; } } }

.catalog {
  &-table {
    &__header {
      background-color: $accentColor;
      border-bottom: 1px solid $headerBg; }
    &__content {
      border-bottom: 1px solid $headerBg;
      color: $headerBg;
      background-color: $secondaryBg;
      &:nth-child(even) {
        background-color: $headerText; } } }
  &-search {
    &__container {
      background-color: rgba($headerBg,.75);
      box-shadow: 0px 0px 50px 0px $accentColor; }
    &__header {
      color: $headerText;
      &:after {
        background-color: $accentColor; } }
    &-items {
      &__tab {
        &:not(.catalog-search-items__tab_active) {
          color: $secondaryBg;
          &:hover,
          &:active {
            color: inherit;
            background-color: $accentColor; } }
        &_active {
          background-color: rgba($secondaryBg,.25); } }
      &__container {
        background-color: rgba($secondaryBg,.25); }
      &__content {
        color: $headerBg;
        &:hover,
        &:active {
          background-color: $accentColor; } } }
    &__icon {
      color: $accentColor; }
    &__form {
      border: 1px solid $secondaryBg;
      background-color: $inputBg;
      textarea,
      input[type='text'],
      select {
        color: $headerBg;
        background-color: transparent;
        &:after {
          color: $headerBg; } } } } }

.catalog1 {
  &-search {
    &__container {
      background-color: rgba($headerBg,.75);
      box-shadow: 0px 0px 50px 0px $accentColor; }
    &__header {
      color: $headerText;
      &:after {
        background-color: $accentColor; } }
    &-items {
      &__tab {}

      &__container {
        background-color: rgba($secondaryBg,.25); }
      &__content {
        color: $headerBg;
        &:hover,
        &:active {
          background-color: $accentColor; } } }
    &__icon {
      color: $accentColor; }
    &__form {
      border: 1px solid $secondaryBg;
      background-color: $inputBg;
      textarea,
      input[type='text'],
      select {
        color: $headerBg;
        background-color: transparent;
        &:after {
          color: $headerBg; } } } } }

.item {
  &__tabs {
    &-header {
      &>div {
        &.active {
          background-color: rgba($secondaryBg,.25); }
        &:not(.active) {
          color: $secondaryBg;
          &:hover,
          &:active {
            background-color: rgba($secondaryBg,.25);
            color: inherit; } } } }
    &-content {
      &-container {
        &.active {
          background-color: rgba($secondaryBg,.25); } }
      &-item {
        a {
          color: inherit; }
        &:hover,
        &:active,
        &.active {
          background-color: $accentColor; } } } }

  &__carousel {
    &-content {
      .item__carousel-img {} }
    &-nav {
      .item__carousel-img {}
      .slick-arrow {
        &:before {
          background-color: $accentColor;
          color: $headerBg; } } } } }



.pagination {
  a {
    color: $headerBg;
    &:not(.active) {
      &:hover,
      &:active {
        background-color: $accentColor; } }
    &.active {
      color: $secondaryBg; } } }

.blog {
  &-post {
    &__date {
      color: $secondaryBg; }
    blockquote {
      &:before,
      &:after {
        border: 3px solid $accentColor; } } } }
.blog-cards {
  .card {
    .type-card__link {
      a {
        &:after {
          background-color: $headerBg; } } }
    &:hover {
      .card {
        &-body {
          background-color: rgba($accentColor,1); } }
      .type-card__link {
        a {
          &:before {
            background-color: $secondaryBg; } } } } } }

.inner-page__accent {
  background-color: rgba($accentColor,.75);
  &-text {
    border-left: 5px solid $headerBg; } }

/* End of site color theme */
