// xs - Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
.header {
  font-weight: 700;
  padding-bottom: 10px;
  &__contacts {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    @include dFlex(flex-start, center);
    flex-wrap: wrap;
    .contact {
      @include dFlex(flex-end, center);
      padding: 5px 0;
      width: 100%;
      &:not(:last-of-type) {
        padding-bottom: 0;
        a {
          margin-right: 5px;
          &:after {
            content: ','; }
          &:last-of-type {
            margin-right: 0;
            &:after {
              content: ''; } } } }
      a {
        text-decoration: none;
        &:hover,
        &:active {
          text-decoration: underline; } } } }
  &__social {
    height: 25px;
    order: -1;
    margin-bottom: 10px;
    @include dFlex(center, center);
    flex-wrap: wrap;
    .social {
      &__container {
        @include dFlex(space-between, center);
        margin: 0 5px;
        height: 100%;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: calc(100% + 5px);
          height: 100%;
          left: 0;
          transform: skewX(-10deg) translateX(-2.5px);
          z-index: 0; }
        a {
          font-size: 16px;
          padding: 0 15px;
          z-index: 1;
          text-decoration: none;
          &:first-of-type {
            padding-left: 20px; }
          &:last-of-type {
            padding-right: 20px; } } } } } }

.menu {
  height: 55px;
  .logo {
    display: block;
    width: 100%;
    height: 55px;
    text-decoration: none;
    background-position: left center; }
  .search {
    width: 100%;
    height: 100%;
    @include dFlex(space-between, center);
    position: relative;
    input {
      width: 100%; }
    svg {
      position: absolute;
      font-size: 16px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: .5; } }
  &__container {
    flex-wrap: wrap;
    position: relative;
    transition: ease-in .5s;
    &.active {
      .menu {
        &__links {
          overflow: visible;
          padding: 15px 0;
          height: auto;
          li {
            height: 30px; } }
        &__symbol {
          transition: ease-in .75s;
          transform: rotate(180deg);
          span {
            &:first-of-type {
              margin-top: -8px;
              transform: translate(-50%, calc(-50% + 8px)) rotate(45deg); }
            &:nth-of-type(2) {
              width: 0; }
            &:last-of-type {
              margin-top: 8px;
              transform: translate(-50%, calc(-50% - 8px)) rotate(-45deg); } } } } } }
  &__burger {
    width: 100%; }
  &__button {
    width: 40px;
    height: 40px;
    margin: 15px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer; }
  &__symbol {
    width: 100%;
    height: 100%;
    position: relative;
    span {
      width: 50%;
      height: 3px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      &:first-of-type {
        margin-top: -8px;
        transform: translate(-50%, -50%) scaleX(1); }
      &:nth-of-type(2) {
        width: calc(50% - 5px);
        transform: translate(calc(-50% - 2.5px), -50%) scaleX(1); }
      &:last-of-type {
        margin-top: 8px;
        transform: translate(-50%, -50%) scaleX(1); } }
    &:hover {
      span {
        transition: ease-in 1s;
        &:first-of-type {}
        &:nth-of-type(2) {
          animation: burger-hover 1.25s infinite ease-in; }
        &:last-of-type {}
        @keyframes burger-hover {
          0% {
            transform: translate(calc(-50% - 2.5px), -50%); }
          25% {
            transform: translate(-50%, -50%); }
          50% {
            transform: translate(calc(-50% + 2.5px), -50%); }
          75% {
            transform: translate(-50%, -50%); }
          100% {
            transform: translate(calc(-50% - 2.5px), -50%); } } } } }

  &__links {
    @include dFlex(flex-start, center);
    width: 100%;
    height: 100%;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    flex-wrap: wrap;
    height: 0;
    overflow: hidden;
    transition: ease-in .5s;
    li {
      display: block;
      width: 100%;
      @include dFlex(flex-start, center);
      height: 0;
      transition: ease-in .5s;
      * {
        transition: ease-in .5s; }
      a {
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          top: -5px;
          left: -5px;
          z-index: -1;
          opacity: 0;
          transition: ease-in .5s; }
        &:hover,
        &:active,
        &.active {
          text-decoration: none;
          &:before {
            opacity: 1; } } }
      &:not(:last-of-type) {
        a {
          &:after {
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 12px;
            content: '';
            padding: 0; } } } } } }

.breadcrumbs {
  font-family: 'Roboto Condensed', sans-serif;
  padding: 8px 15px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 0;
  span,
  a {
    white-space: nowrap; } }

.first-screen {
  &_catalog {
    padding-top: 15px;
    margin-bottom: 15px; }
  &__text {
    font-weight: 700;
    font-size: 32px;
    line-height: 22px;
    word-spacing: 600px;
    text-transform: uppercase;
    margin: 75px 0 30px 0;
    @include dFlex(center, flex-start);
    flex-direction: column;
    span {
      display: block;
      word-spacing: 600px; } }
  &__carousel {
    margin: 25px -15px;
    width: calc(100% + 30px);
    height: 350px;
    .carousel {
      &-inner {
        height: 100%; }
      &-item {
        height: 100%; }
      &-img {
        height: 100%; }
      &-indicators {
        cursor: pointer;
        margin-bottom: 2.5px;
        li {
          height: 5px;
          width: 30px;
          border: 1px solid;
          margin: 0 7.5px; } }
      &-caption {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 5px 15px 20px 15px;
        h5 {
          font-family: 'Roboto', sans-serif;
          font-weight: 900;
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 15px;
          text-align: center;
          span {
            position: relative;
            display: block;
            &:after {
              content: '';
              width: 100%;
              height: 5px;
              position: absolute;
              left: 0;
              bottom: -5px; } } }

        p {
          padding: 0;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          line-height: 18px; } } } } }

h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin: 15px 0; }
.brands {
  @include dFlex(center, center);
  flex-wrap: wrap;
  &__container {
    height: 30px;
    width: 50px;
    margin: 0 5px 15px 5px; } }

.type-card {
  padding: 15px 0;
  min-height: 100%;
  &__container {
    position: relative;
    padding: 15px;
    padding-bottom: 10px;
    height: 100%;
    * {
      transition: ease-in .5s; }
    &:hover {
      .type-card {
        &__overlay {
          opacity: .9; } } } }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .25; }
  &__header {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    position: relative;
    margin-bottom: 15px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -7px;
      width: 100%;
      height: 2px;
      transition: ease-in .5s; } }
  &__text {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    z-index: 100;
    position: relative; }
  &__link {
    text-align: right;
    padding-top: 5px;
    a {
      position: relative;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      color: inherit;
      z-index: 1;
      &:hover,
      &:active {
        text-decoration: none; }
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 0px;
        left: -5px;
        transition: ease-in .5s;
        z-index: -1; }
      &:after {
        content: '';
        width: calc(100% + 5px);
        height: 1px;
        position: absolute;
        left: -5px;
        bottom: -1px;
        transition: ease-in .5s; } } } }
.screen-header {
  font-size: 26px;
  font-weight: 300;
  line-height: 34px;
  text-transform: uppercase;
  text-align: center;
  margin: 15px 0; }
.screen-description {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  text-align: center;
  padding-top: 5px;
  margin-bottom: 50px;
  &_catalog {
    margin-bottom: 30px; } }
.select {
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 50px;
  &__header {
    padding: 13px 10px;
    font-weight: 700; }
  &__item {
    display: block;
    padding: 12px 10px;
    cursor: pointer;
    transition: ease-in .25s;
    border-top: 1px solid;
    border-bottom: 1px solid;
    position: relative;
    &:first-of-type {
      border-top: 0;
      padding-top: 13px; }
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 13px; }
    &:hover,
    &:active,
    &.active {
      text-decoration: none; }
    &.active {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 25px;
        height: 25px;
        transform: translate(50%, -50%) rotate(45deg);
        display: none;
        z-index: -1; } } } }

.params {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 50px;
  * {
    transition: ease-in .5s; }
  &__download {
    padding: 9.5px 10px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 24px;
    @include dFlex(flex-start, center);
    &:hover,
    &:active {
      text-decoration: none; }
    svg {
      font-size: 31px;
      margin-right: 10px; } }
  &__item {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    min-height: 50px;
    @include dFlex(space-between, center);
    border-top: 1px solid;
    border-bottom: 1px solid;
    position: relative;
    &:first-of-type {
      border-top: 0;
      padding-top: 1px; }
    &:nth-last-child(n-1) {
      border-bottom: 0;
      padding-bottom: 1px; } }
  &__name {
    padding-left: 10px;
    width: 33%; }
  &__value {
    padding: 0 10px;
    width: 67%; }
  &__button {
    @include dFlex(center, center); }
  &__header {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin: 15px 0;
    text-transform: uppercase;
    font-weight: 700; } }

.button {
  display: inline-block;
  border: none;
  position: relative;
  height: 50px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: calc(100% + 10px);
    top: 0;
    left: 0;
    transform: translateX(-5px) skewX(-10deg);
    z-index: -1; } }
.item {
  margin-bottom: 50px;
  .carousel {
    width: 100%;
    height: 350px;
    &-inner {
      width: 100%;
      height: 100%; }
    &-item {
      width: 100%;
      height: 100%;
      padding-top: 110px; }
    &-img {
      width: 100%;
      height: 100%; }
    &-caption {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      z-index: 1;

      text-align: left;
      h5 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 300;
        line-height: 26px; } }
    &-controls {
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      width: 100%;
      .button {
        display: inline-block;
        padding: 0 5px;
        font-size: 32px;
        line-height: 50px;
        text-align: center;
        vertical-align: baseline;
        z-index: 1;
        &:first-of-type {
          margin-left: 10px;
          margin-right: 25px; } } } } }

.slick-track {
  min-width: 100%; }

.tabs {
  padding-top: 15px;
  &__button {
    @include dFlex(center, center);
    min-height: 50px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    transition: ease-in .5s;
    margin-bottom: 15px;
    &.active,
    &:hover,
    &:active {
      cursor: pointer; } }
  &__content {
    padding: 0;
    position: relative;
    &:not(.active) {
      display: none; }
    &_preloader {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: url('../img/preloader.gif') center no-repeat;
        opacity: .9; } }
    &-container {
      min-height: 200px;
      position: relative;
      margin: 0 -15px 30px -15px;
      .video {
        &__play {
          font-size: 75px; } } } }
  &__card {
    position: relative;
    margin-bottom: 30px;
    height: calc(100% - 30px);
    * {
      transition: ease-in .5s; }
    &-bg {
      position: absolute;
      width: 100%;
      height: 100%; }
    &-content {
      position: relative;
      height: 100%; }
    &-img {
      position: relative;
      height: 150px;
      width: 100%;
      svg {
        font-size: 50px; }
      .carousel {
        width: 100%;
        height: 100%;
        position: relative;
        &-indicators {
          left: 0;
          bottom: 0;
          height: 30px;
          margin: 0;
          @include dFlex(center, center);
          li {
            width: 15px;
            height: 5px;
            border: 1px solid;
            cursor: pointer; } }
        &-inner {
          height: 100%; }
        &-item {
          height: 100%; }
        &-img {
          height: 100%; } } }
    &-text {
      position: relative;
      padding: 10px 15px;
      text-align: right;
      width: 100%;
      min-height: calc(100% - 150px);
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        padding-bottom: 10px;
        margin-bottom: 10px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 0;
          transition: ease-in .5s; } }
      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        display: inline-block;
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -5px;
          width: 10px;
          height: 10px;
          z-index: -1;
          transition: ease-in .5s; }
        &:after {
          content: '';
          width: calc(100% + 5px);
          height: 1px;
          position: absolute;
          bottom: -2px;
          left: -5px;
          transition: ease-in .5s; } } } } }

.fourth-screen {
  padding: 30px 0;
  .screen-header {
    margin: 0; } }
.parthner {
  &__card {
    margin-top: 50px;
    &-container {
      padding: 15px;
      min-height: 100%; } }
  &__icon {
    text-align: center;
    svg {
      font-size: 50px;
      line-height: 52px; } }
  &__header {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 0;
    margin-bottom: 10px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px; } }
  &__text {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    text-align: center; } }

.contacts {
  &-screen {
    padding: 50px 0;
    .screen-header {
      margin: 0;
      margin-bottom: 30px; } }
  &-place {
    margin-bottom: 10px;
    &__header {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 10px;
      padding-left: 0; }
    &__container {
      position: relative; }
    &__map {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    &__text {
      font-size: 18px;
      line-height: 25px;
      &> .row {
        padding: 15px 0;
        &:not(:last-of-type) {
          padding-bottom: 0; } } }
    &__param {
      font-weight: 300; }
    &__value {
      font-weight: 500; } }
  &-form {
    &__container {
      padding: 20px 30px;
      margin-top: 30px; }
    &__header {
      font-weight: 100;
      font-size: 19px;
      line-height: 30px;
      text-transform: uppercase;
      padding: 0;
      text-align: center;
      span {
        font-weight: 500;
        display: block; } }
    form {
      padding: 15px 0;
      input,
      textarea {
        width: 100%;
        font-weight: 100;
        font-size: 16px;
        line-height: 25px;
        padding: 5px 10px; }
      input {
        height: 30px;
        margin-bottom: 20px; }
      textarea {
        height: 75px;
        resize: none; } }
    &__button {
      @include dFlex(center, center);
      padding-top: 15px;
      .button {
        z-index: 1; } } } }

.video {
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: ease-in .5s; } }

.footer {
  padding-top: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  &__logo {
    height: 90px;
    background-position: center top !important;
    margin-bottom: 30px; }
  &__social {
    @include dFlex(center, center);
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    a {
      margin: 0 15px;
      &:first-of-type {
        margin-left: 0; }
      &:last-of-type {
        margin-right: 0; } } }
  &__header {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px; }
  &__links {
    li {
      display: block;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 30px; }
      a {
        font-size: 14px;
        line-height: 18px; } } } }

.catalog {
  &-table {
    &__header {
      font-family: 'Roboto Condensed';
      font-weight: 700;
      text-transform: uppercase;
      min-height: 50px;
      font-size: 18px;
      line-height: 24px;
      div {
        @include dFlex(flex-start, center); } }
    &__content {
      font-family: 'Roboto Condensed';
      font-weight: 500;
      text-decoration: none;
      min-height: 50px;
      font-size: 18px;
      line-height: 24px;
      div {
        @include dFlex(flex-start, center);
        flex-wrap: wrap;
        &:nth-of-type(2) {
          text-transform: uppercase; }
        span {
          display: block;
          width: 100%;
          font-size: 12px;
          opacity: .75;
          text-transform: none; } }
      &:hover {
        text-decoration: none;
        opacity: .9; }
      &:nth-child(even) {} } }

  &-search {
    margin-bottom: 45px;
    &-results {
      padding-top: 15px; }
    &-items {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 50px;
      &__container {
        padding: 0 0 10px 0; }
      .row {
        margin: 0; }
      &__content {
        padding: 0;
        transition: ease-in .25s;
        &:hover,
        &:active {
          text-decoration: none;
          color: inherit; }
        &-text {
          @include dFlex(flex-start, center); } }

      &__tab {
        text-align: center;
        min-height: 50px;
        text-transform: uppercase;
        @include dFlex(flex-start, center);
        transition: ease-in .25s; }
      &__content {
        &-img {
          margin: 5px 0;
          div {
            height: 100%; } }
        &-text {
          min-height: 60px; } } }
    &__container {
      padding-top: 15px;
      padding-bottom: 30px; }
    &__column {}
    &__icon {
      svg {
        font-size: 50px; }
      &-select {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        z-index: -1; } }
    &__form {
      overflow: hidden;
      position: relative;
      z-index: 1;
      &:not(:last-of-type) {
        margin-bottom: 20px; }
      &:last-of-type {
        margin-bottom: 15px; }
      input {
        border: none;
        width: 100%; }
      select {
        border: none;
        width: calc(100% + 30px);
        z-index: 1;
        option {
          height: 30px;
          background-color: transparent; } } }
    &__header {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-style: italic;
      text-align: center;
      text-transform: uppercase;
      padding: 15px;
      position: relative;
      margin: 0 0 10px 0;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px; } }
    &__button {
      @include dFlex(center, center); } } }

.catalog1 {
  &-search {
    margin-bottom: 45px;
    &-results {
      padding-top: 15px; }
    &-items {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 50px;
      &__container {
        padding: 10px 0; }
      .row {
        margin: 0; }
      &__content {
        padding: 0;
        transition: ease-in .25s;
        &:hover,
        &:active {
          text-decoration: none;
          color: inherit; }
        &:not(.catalog-search-items__content_active) {
          display: none; }
        &-text {
          @include dFlex(flex-start, center); } }
      &__tab {
        text-align: center;
        min-height: 50px;
        text-transform: uppercase;
        @include dFlex(center, center);
        transition: ease-in .25s;
        &:not(.catalog-search-items__tab_active) {
          cursor: pointer; } }
      &__content {
        &-img {
          margin: 5px 0;
          div {
            height: 100%; } }
        &-text {
          min-height: 60px; } } }
    &__container {
      padding-top: 15px;
      padding-bottom: 30px; }
    &__column {}
    &__icon {
      svg {
        font-size: 50px; }
      &-select {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        z-index: -1; } }
    &__form {
      overflow: hidden;
      position: relative;
      z-index: 1;
      &:not(:last-of-type) {
        margin-bottom: 20px; }
      &:last-of-type {
        margin-bottom: 15px; }
      input {
        border: none;
        width: 100%; }
      select {
        border: none;
        width: calc(100% + 30px);
        z-index: 1;
        option {
          height: 30px;
          background-color: transparent; } } }
    &__header {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-style: italic;
      text-align: center;
      text-transform: uppercase;
      padding: 15px;
      position: relative;
      margin: 0 0 10px 0;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px; } }
    &__button {
      @include dFlex(center, center); } } }

.item {
  &__container {}

  &__header {
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    text-transform: uppercase; }

  &__tabs {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    min-height: 50px;
    &-header {
      @include dFlex(center, center);
      &:first-child {
        padding-right: 0; }
      &:last-child {
        padding-left: 0; }
      &>div {
        @include dFlex(center, center);
        width: 100%;
        height: 100%;
        transition: ease-in .25s;
        &:not(.active) {
          cursor: pointer; } } }
    &-content {
      min-height: 0;
      &-container {
        &:not(.active) {
          display: none; } }
      &-item {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 18px;
        line-height: 30px;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        padding: 0 15px;
        transition: ease-in .25s;
        cursor: pointer;
        svg {
          margin-right: 10px; }
        &>ul {
          padding: 0 0px; }
        * {
          transition: ease-in .25s; }
        &.active {
          &>svg {
            transform: rotate(90deg); } }
        &:not(.active) {
          &>ul {
            display: none; } } }
      &-subitem {
        display: block;
        margin-left: 15px;
        &:not(.active) {
          &>ul {
            display: none; } }
        &.active {
          &>svg {
            transform: rotate(90deg); } }
        a {
          margin-left: 20px; } } } }
  &__carousel {
    &-content {
      margin: 15px 0;
      .item__carousel-img {
        height: 220px; } }
    &-nav {
      position: relative;
      margin-bottom: 15px;
      .item__carousel-img {
        height: 50px; }
      .slick-arrow {
        width: 20px;
        height: 100%;
        position: absolute;
        top: 50%;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: calc(100% + 10px);
          top: 0;
          left: 0;
          transform: translateX(-5px) skewX(-10deg);
          z-index: -1;
          opacity: 1;
          @include dFlex(center, center); } }
      .slick-prev {
        left: 0;
        &:before {
          content: '<'; } }
      .slick-next {
        right: 0;
        &:before {
          content: '>'; } } }

    &-img {} }

  &__params {} }

.pagination {
  @include dFlex(center, center);
  margin: 30px 0;
  a {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    padding: 0 10px;
    text-decoration: none;
    &.active {
      cursor: default; } } }

.blog {
  margin-bottom: 30px;
  &-post {
    margin-bottom: 30px;
    .blog-post__img-container {
      img {
        margin: 0;
        margin-bottom: 15px; } }
    &__date {
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      margin-bottom: 15px;
      svg {
        margin-right: 10px; } }
    p {
      font-size: 14px;
      line-height: 17px;
      font-weight: 300;
      margin-bottom: 15px;
      strong {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        font-style: italic; } }
    img {
      width: 100%;
      margin: 15px 0; }
    blockquote {
      @include dFlex(center, center);
      margin: 15px 0;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      text-align: center;
      padding: 15px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;
        left: 0;
        border-bottom: none;
        border-right: none; }
      &:after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        bottom: 0;
        right: 0;
        border-top: none;
        border-left: none; } } } }


.blog-cards {
  column-count: 1;
  column-gap: 30px;
  &_post {
    column-count: 1; }
  img {
    margin: 0;
    border-radius: 0; }
  .card {
    border: none;
    border-radius: 0;
    margin-bottom: 30px;
    &-body {
      transition: ease-in .25s; }
    &-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 10px; }
    &-text {
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      margin-bottom: 10px; }
    &-date {
      font-size: 10px;
      font-weight: 500;
      line-height: 13px;
      svg {
        margin-right: 10px; } } } }

.first-screen_blog-post {
  padding: 30px 0;
  ul {
    li {
      display: block; } }
  blockquote {
    margin: 50px 0; } }
.inner-page__accent {
  margin: 30px -15px;
  padding: 50px 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  &-header {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700; }
  &-text {
    position: relative;
    padding-left: 15px;
    p {
      margin-bottom: 15px; } } }


