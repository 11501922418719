// Mobile-first - Bootstrap 4

// xs - Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
@import 'adaptive_xs';

// sm - Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  @import 'adaptive_sm'; }

// md - Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  @import 'adaptive_md'; }

// lg - Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  @import 'adaptive_lg'; }

// xl - Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  @import 'adaptive_xl'; }
