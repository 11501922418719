// lg - Large devices (desktops, 992px and up)

.header {
  padding-bottom: 0;
  &__contacts {
    font-size: 10px;
    height: 25px;
    flex-wrap: nowrap;
    padding: 0 15px;
    .contact {
      height: 100%;
      &:not(:last-of-type) {
        a {
          &:last-of-type {
            margin-right: 25px; } } } } }
  &__social {
    order: 0;
    margin-bottom: 0; } }

.menu {
  padding-top: 0;
  &__links {
    @include dFlex(flex-end, center);
    height: 100%;
    li {
      @include dFlex(flex-start, center);
      height: auto;
      &:first-of-type {
        padding-left: 0; } } } }

.first-screen {
  &__text {
    margin: 0;
    min-height: 400px;
    font-size: 48px;
    line-height: 34px; }
  &__carousel {
    height: calc(100% - 50px); } }

.select {
  &__item {
    &.active {
      &:after {
        display: block; } } } }

.item {
  .carousel {
    min-height: 100%;
    &-caption {
      height: 60px; } } }

.tabs {
  padding: 30px 15px;
  &__button {
    margin: 0; }
  &__content {
    &-container {
      min-height: 300px; } } }

.contacts {
  &-form {
    &__container {
      margin-top: 0; } } }

.footer {
  &__social {
    margin-bottom: 0; } }

.blog-cards {
  column-count: 4;
  &_post {
    column-count: 1; } }
